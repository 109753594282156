<template>
  <div class="py-2">
    <div>
      <v-col
        cols="12"
        v-if="messages && messages.length > 0"
        class="msg-block"
        ref="msgBlock"
      >
        <div
          :key="`msg_${msg.id}`"
          v-for="msg in messages"
          :class="`chat-msg ${msgType(msg)}`"
        >
          <div class="chat-name">
            {{ msg.identity_name }} {{ formatDateTime(msg.created_at) }}
          </div>
          <div class="chat-content">
            <img
              v-if="msg.file"
              :src="urlFile(msg)"
              style="max-width: 100%"
              @click="showImages(urlFile(msg))"
            />
            <span v-if="msg.content" style="white-space: pre-line">{{
              msg.content
            }}</span>
          </div>
        </div>
      </v-col>
      <div v-if="checkRoleInput">
        <v-text-field
          v-model="content"
          :label="$t('labels.insert_comment')"
          dense
          outlined
          clearable
          hide-details
          single-line
          @keyup.enter="sendNewMessage"
        >
          <template v-slot:append>
            <img
              class="cursor-pointer"
              width="24"
              height="24"
              src="@/assets/internal_requests/add-image.png"
              @click="$refs.inputUploadFile.click()"
            />
          </template>
        </v-text-field>
        <input
          type="file"
          ref="inputUploadFile"
          accept="image/*"
          @change="onInputFileChange"
          class="d-none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { api as viewerApi } from "v-viewer";

export default {
  name: "Messages",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isShowInput: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    content: null,
    file: null,
    isLoading: false,
    messages: [],
  }),
  computed: {
    urlFile() {
      return (msg) => process.env.VUE_APP_FILE_CDN_URL + "/" + msg.file;
    },
    imageUrls() {
      const urls = [];
      this.messages.forEach((i) => {
        if (i.file) {
          urls.push(this.urlFile(i));
        }
      });
      return urls;
    },
    checkRoleInput() {
      if (!window.me || !window.me.identity_id) {
        return false;
      }
      if (!this.isShowInput) {
        return false;
      }
      const identityId = window.me.identity_id;
      return (
        identityId === this.item.id_identity_create ||
        (this.item.request_member_ids &&
          this.item.request_member_ids.includes(`Ω${identityId}Ω`))
      );
    },
    msgType() {
      if (!window.me || !window.me.identity_id) {
        return "chat-type-1";
      }
      const identityId = window.me.identity_id;
      return (msg) =>
        identityId === msg.id_identity ? "chat-type-2" : "chat-type-1";
    },
  },
  methods: {
    formatDateTime,
    onInputFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.uploadFile(files[0]);
    },

    uploadFile(file) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let fd = new FormData();
      fd.append("file", file);
      httpClient
        .post(`/common/v1/upload-image`, fd)
        .then((res) => {
          this.isLoading = false;
          this.sendMessage({ id: this.item.id, file: res.data });
          this.$refs.inputUploadFile.value = null;
        })
        .catch((err) => {
          console.log("uploadFile err", err);
          this.$refs.inputUploadFile.value = null;
          this.isLoading = false;
        });
    },
    async sendNewMessage() {
      this.sendMessage({ id: this.item.id, content: this.content });
      this.content = null;
    },
    async sendMessage(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/internal-request/v1/add-content", {
          id_internal_request: item.id,
          content: item.content,
          file: item.file,
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.warning(errMsg);
      }
    },
    async getMessages() {
      const { data } = await httpClient.post("/internal-request/v1/detail", {
        id_internal_request: this.item.id,
      });
      this.messages = [...data];
      this.scrollBottom();
    },
    showImages(url) {
      const index = this.imageUrls.findIndex((u) => u === url);
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: this.imageUrls,
      });
    },
    listenSocket(data) {
      if (this.item.id === data.id_internal_request) {
        this.messages.push(data);
        this.scrollBottom();
      }
    },
    scrollBottom() {
      this.$nextTick(() => {
        if (this.$refs.msgBlock) {
          this.$refs.msgBlock.scrollTo(
            0,
            this.$refs.msgBlock.scrollHeight + 200
          );
        }
      });
    },
  },
  mounted() {
    this.getMessages();
    this.$root.$on("internalRequestListenSocket", this.listenSocket);
  },
  beforeDestroy() {
    this.$root.$off("internalRequestListenSocket", this.listenSocket);
  },
};
</script>

<style scoped lang="scss">
.msg-block {
  width: 100%;
  min-width: 300px;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 360px;
  overflow-y: auto;
  border: solid 1px black;
  border-radius: 3px;
  background-color: white;
}

.chat-msg {
  margin-bottom: 10px;
}

.chat-name {
  background-color: transparent;
  font-style: italic;
  font-size: 2.5mm;
  color: grey;
}

.chat-type-1 {
  .chat-content {
    padding: 5px;
    width: auto;
    max-width: 80%;
    //float: left;
    background-color: lightgrey;
    border: 1px solid transparent;
    border-radius: 10px;
    text-align: left;
    text-wrap: wrap;
  }
}

.chat-type-2 {
  //float: right;
  text-align: right;

  .chat-content {
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: dodgerblue !important;
    color: white !important;
    width: 80%;
    margin-left: 20%;
    text-wrap: wrap;
    //float: right;
  }
}
</style>
